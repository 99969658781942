import {
   DermIcon,
   FizIcon,
   GemIcon,
   Gilbert,
   Johnston,
   KardIcon,
   NevrIcon,
   OftalIcon,
   OnkolIcon,
   Rory,
   Saj,
   TerapIcon,
   UrolIcon,
   Veronica,
} from '../../assets'

export const MED_SERVICE = [
   {
      id: 2,
      title: 'Педиатрия',
   },
   {
      id: 4,
      title: 'УЗИ',
   },
   {
      id: 5,
      title: 'Гинекология',
   },
   {
      id: 6,
      title: 'ЛОР',
   },
   {
      id: 1,
      title: 'Семейная медицина',
   },
   {
      id: 3,
      title: 'Медицина внутренних органов',
   },
]

export const INTERVAL_IN_MINUTES = [
   {
      id: 1,
      title: '15 минут',
      time: 15,
   },
   {
      id: 2,
      title: '20 минут',
      time: 20,
   },
   {
      id: 3,
      title: '30 минут',
      time: 30,
   },
   {
      id: 4,
      title: '45 минут',
      time: 45,
   },
   {
      id: 5,
      title: '1 час',
      time: 60,
   },
   {
      id: 6,
      title: '1,5 часа',
      time: 90,
   },
]

export const TRANSLATED_MED_SERVICES = {
   ALLERGOLOGY: 'Аллергология',

   VACCINATION: 'Вакцинация',

   GYNECOLOGY: 'Гинекология',

   CARDIOLOGY: 'Кардиология',

   NEUROSURGERY: 'Нейрохирургия',

   ORTHOPEDICS: 'Ортопедия',

   OPHTHALMOLOGY: 'Офтальмология',

   PSYCHOTHERAPY: 'Психотерапия',

   RHEUMATOLOGY: 'Ревматология',

   UROLOGY: 'Урология',

   ENDOCRINOLOGY: 'Эндокринология',

   ANESTHESIOLOGY: 'Анестезиология',

   GASTROENTEROLOGY: 'Гастроэнтерология',

   DERMATOLOGY: 'Дерматология',

   NEUROLOGY: 'Неврология',

   ONCOLOGY: 'Онкология',

   OTOLARYNGOLOGY: 'Отоларингология',

   PROCTOLOGY: 'Проктология',

   PULMONOLOGY: 'Пульмонология',

   THERAPY: 'Терапия',

   FLEBOLOGY: 'Флебология',

   PHYSIOTHERAPY: 'Физиотерапия',
}

export const TRANSLATED_MED_SERVICES_ENG = {
   Педиатрия: 'PEDIATRICS',

   УЗИ: 'ULTRASOUND',

   Гинекология: 'GYNECOLOGY',

   ЛОР: 'LOR',

   'Семейная медицина': 'FAMILY_MEDICINE',

   'Медицина внутренних органов': 'INTERNAL_MEDICINE',
}

export const DEPARTMENTS = [
   {
      id: 2,
      title: 'Педиатрия',
   },
   {
      id: 4,
      title: 'УЗИ',
   },
   {
      id: 5,
      title: 'Гинекология',
   },
   {
      id: 6,
      title: 'ЛОР',
   },
   {
      id: 1,
      title: 'Семейная медицина',
   },
   {
      id: 3,
      title: 'Медицина внутренних органов',
   },
]
export const MAIN_MED_SERVICES = [
   {
      id: 1,
      img: <GemIcon />,
      title: 'Вакцинация',
   },
   {
      id: 2,
      img: <KardIcon />,
      title: 'Кардиология',
   },
   {
      id: 3,
      img: <NevrIcon />,
      title: 'Неврология',
   },
   {
      id: 4,
      img: <DermIcon />,
      title: 'Дермотология',
   },
   {
      id: 5,
      img: <OftalIcon />,
      title: 'Офтальмология',
   },
   {
      id: 6,
      img: <TerapIcon />,
      title: 'Терапия',
   },
   {
      id: 7,
      img: <FizIcon />,
      title: 'Физиотерапия',
   },
   {
      id: 8,
      img: <OnkolIcon />,
      title: 'Онкология',
   },
   {
      id: 9,
      img: <UrolIcon />,
      title: 'Урология',
   },
]

export const BEST_DOCTORS_IMAGES = [
   {
      id: 1,
      img: Veronica,
      name: 'Dr. Veronica',
      description: 'Внутренняя и семейная медицина',
   },
   {
      id: 2,
      img: Johnston,
      name: 'Dr. Dan Johnston',
      description: 'Ухо, нос, горло и семейная медицина',
   },
   {
      id: 3,
      img: Rory,
      name: 'Dr. Rory',
      description: 'Гинекология и семейная медицина',
   },
   {
      id: 4,
      img: Gilbert,
      name: 'Dr. Dan Gilbert',
      description: 'Гинекология и семейная медицина',
   },
   {
      id: 5,
      img: Saj,
      name: 'Dr. Saj',
      description: 'Ультразвуковая диагностика и педиатр',
   },
]
