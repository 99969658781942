export const faq = [
   {
      id: 1,
      title: 'Как проходит процедура?',
      data: 'На первом этапе, консультации нашего специалиста, вам будет нужно определиться с желаемым результатом — тоном отбеливания при помощи специальной палитры. Дальнейшие действия выполняются в следующей последовательности:',
      list: [
         {
            text: 'Обработка десен защитным составом;',
         },
         {
            text: 'Защита глаз при помощи специальных очков;',
         },
         {
            text: 'Нанесение отбеливающего геля;',
         },
         {
            text: 'Первая процедура отбеливания в течение 15 минут под воздействием лампы;',
         },
         {
            text: 'Полоскание для удаления остатков геля;',
         },
         {
            text: 'Вторая процедура отбеливания в течение по 15 минут под воздействием лампы;',
         },
         {
            text: 'Полоскание;',
         },
         {
            text: 'Третья процедура отбеливания в течение 15 минут под воздействием лампы;',
         },
         {
            text: 'Нанесение реминерализирующего геля для защиты эмали от чувствительности и ее восстановления.',
         },
      ],
   },
   {
      id: 2,
      title: 'Показания',
      data: 'Процедура отбеливания зубов может быть рекомендована в следующих случаях:',
      list: [
         {
            text: 'Пигментация и окрашивание зубов от кофе, чая, табака и других пигментирующих продуктов.',
         },
         {
            text: 'С возрастом эмаль зубов теряет свою белизну и может потребовать освежения.',
         },
         {
            text: 'Пациенты, желающие улучшить внешний вид своей улыбки и уверенность в себе.',
         },
         {
            text: 'Пациенты, у которых нет серьезных заболеваний десен и зубов, и их состояние позволяет провести процедуру.',
         },
      ],
   },
   {
      id: 3,
      title: 'Противопоказания',
      data: 'Однако процедура отбеливания зубов не рекомендуется в следующих случаях:',
      list: [
         {
            text: 'Беременность и кормление грудью.',
         },
         {
            text: 'Дети в возрасте до 18 лет, так как структура и эмаль зубов в этом возрасте все еще формируется.',
         },
         {
            text: 'Люди с серьезными заболеваниями десен или зубов, такими как кариес, периодонтит и другие.',
         },
         {
            text: 'Люди с аллергией на компоненты используемых в процедуре отбеливания средств.',
         },
         {
            text: 'Пациенты, у которых есть острые боли в зубах или деснах.',
         },
      ],
   },
   {
      id: 4,
      title: 'Насколько безопасно отбеливание Zoom 4?',
      data: 'На процедуре отбеливания Zoom 4 мы обеспечиваем максимальную безопасность и комфорт для наших пациентов. Процедура состоит из нескольких этапов, и каждый из них тщательно разработан, чтобы обеспечить оптимальный результат и минимизировать риски. Вот, как проходит процедура Zoom 4:',
      list: [
         {
            text: 'Консультация специалиста: В первую очередь, вы проведете консультацию с нашим специалистом, чтобы обсудить ваши цели и определить желаемый тон отбеливания с использованием специальной палитры.',
         },
         {
            text: 'Защита десен: Мы начинаем процедуру с тщательной обработки ваших десен защитным составом, чтобы предотвратить их раздражение.',
         },
         {
            text: 'Защита глаз: Для вашего комфорта и безопасности мы предоставляем специальные очки для защиты глаз от воздействия отбеливающей лампы.',
         },
         {
            text: 'Нанесение отбеливающего геля: Отбеливающий гель наносится на ваши зубы, и процедура начинается.',
         },
         {
            text: 'Первая процедура отбеливания: Ваша улыбка будет подвергнута первой процедуре отбеливания в течение 15 минут под воздействием специальной лампы.',
         },
         {
            text: 'Полоскание: После первой процедуры гель удаляется, и ваша улыбка полоскается для удаления остатков геля.',
         },
         {
            text: 'Вторая и третья процедуры отбеливания: Аналогичные процедуры проводятся второй и третий раз в течение по 15 минут каждая под воздействием лампы.',
         },
         {
            text: 'Третья процедура отбеливания в течение 15 минут под воздействием лампы;',
         },
         {
            text: 'Нанесение реминерализирующего геля: Для защиты эмали от чувствительности и ее восстановления после отбеливания, наносится специальный реминерализирующий гель.',
         },
      ],
   },
   {
      id: 5,
      title: 'Сколько держится результат?',
      data: 'Длительность сохранения результата отбеливания Zoom 4 может различаться в зависимости от индивидуальных особенностей и привычек пациента. Однако в среднем результат может сохраняться от нескольких месяцев до двух лет. Чтобы продлить эффект отбеливания, рекомендуется соблюдать следующие советы:',
      list: [
         {
            text: 'Регулярная гигиеническая чистка: Поддерживайте правильную гигиеническую чистку рта, включая чистку зубов и использование зубной нити. Это поможет предотвратить образование налета и пятен на зубах.',
         },
         {
            text: 'Избегание красящих продуктов: Постарайтесь ограничить потребление красящих продуктов, таких как кофе, чай, вино, и сигареты. Эти продукты могут способствовать появлению пятен на зубах.',
         },
         {
            text: 'Регулярные визиты к стоматологу: Посещайте стоматолога на регулярной основе для профессиональной гигиенической чистки и оценки состояния вашей улыбки. Стоматолог сможет предостеречь и решить проблемы, которые могут повлиять на долгосрочный результат отбеливания.',
         },
         {
            text: 'Используйте специальные зубные пасты и ополаскиватели: Некоторые зубные пасты и ополаскиватели могут помочь поддерживать белизну зубов. Вы можете проконсультироваться со своим стоматологом относительно подходящих продуктов.',
         },
         {
            text: 'Избегайте чрезмерной потребности в отбеливании: Переусердствовать с отбеливанием может привести к чувствительности зубов и даже повреждению эмали. Следуйте рекомендациям специалиста относительно частоты процедур.',
         },
         {
            text: 'Вторая процедура отбеливания в течение по 15 минут под воздействием лампы;',
         },
         {
            text: 'Полоскание;',
         },
         {
            text: 'Третья процедура отбеливания в течение 15 минут под воздействием лампы;',
         },
         {
            text: 'Нанесение реминерализирующего геля для защиты эмали от чувствительности и ее восстановления.',
         },
      ],
   },
]

export const prices = [
   {
      id: 1,
      title: 'consultationFromRailwayStation',
      price: 0,
   },
   {
      id: 2,
      title: 'consultationFromCity',
      price: 500,
   },
   {
      id: 26,
      title: 'consultationAroundCity',
      price: 100,
   },
   {
      id: 3,
      title: 'consultationForForeigners',
      price: 2000,
   },
   {
      id: 4,
      title: 'womenExamination',
      price: 100,
   },
   {
      id: 5,
      title: 'checkingMicroscope',
      price: 150,
   },
   {
      id: 6,
      title: 'bloodSugarFromFinger',
      price: 70,
   },
   {
      id: 7,
      title: 'expressUrineTest',
      price: 80,
   },
   {
      id: 8,
      title: 'expressHemoglobin',
      price: 200,
   },
   {
      id: 9,
      title: 'bloodSampling',
      price: 70,
   },
   {
      id: 10,
      title: 'jetInjection',
      price: 75,
   },
   {
      id: 11,
      title: 'intramuscularInjection',
      price: 50,
   },
   {
      id: 12,
      title: 'infusionTherapy',
      price: 150,
   },
   {
      id: 13,
      title: 'pregnancyTest',
      price: 60,
   },
   {
      id: 14,
      title: 'bandaging',
      price: 100,
   },
   {
      id: 15,
      title: 'sutureApplicationGeneral',
      price: 450,
   },
   {
      id: 16,
      title: 'sutureRemoval',
      price: 50,
   },
   {
      id: 17,
      title: 'earCleaning',
      price: 60,
   },
   {
      id: 18,
      title: 'IUDRemoval',
      price: 300,
   },
   {
      id: 27,
      title: 'IUDInstallationSilver',
      price: 1500,
   },
   {
      id: 28,
      title: 'IUDInstallationCopper',
      price: 1000,
   },
   {
      id: 20,
      title: 'inhalationTherapy',
      price: 50,
   },
   {
      id: 21,
      title: 'exchangeCardForPregnant',
      price: 350,
   },
   {
      id: 22,
      title: 'certificate',
      price: 40,
   },
   {
      id: 23,
      title: 'ECG',
      price: 300,
   },
   {
      id: 24,
      title: 'ultrasoundEachOrgan',
      price: 150,
   },
   {
      id: 25,
      title: 'ultrasoundAbdominal',
      price: 500,
   },
]
